<template>
  <div
    class="background-cover"
  />
  <div
    class="unsaved-changes-popup-wrapper"
  >
    <div class="unsaved-changes-header">
      Unsaved changes
    </div>
    <div class="unsaved-changes-message">
      {{ message }}
    </div>
    <div class="unsaved-changes-buttons">
      <Button
        @click="leaveCancel"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        @click="leaveConfrim"
      >
        Proceed
      </Button>
    </div>
  </div>
</template>
<script>
import Button from '@/components/common/Button';

export default {
  emits: ['onLeaveCancel', 'onLeaveConfirm'],
  components: {
    Button,
  },
  props: {
    message: {
      type: String,
      default: () => 'You have unsaved changes, do you want to continue?',
    },
  },
  setup(props, { emit }) {
    const leaveCancel = () => emit('onLeaveCancel');
    const leaveConfrim = () => emit('onLeaveConfirm');

    return {
      leaveCancel,
      leaveConfrim,
    };
  },
};
</script>
<style lang="scss">
.background-cover {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(#000, 0.5);
  z-index: $modalBackgroundZIndex;
  left: 0;
  top: 0;
}
.unsaved-changes-popup-wrapper {
    position: absolute;
    width: 440px;
    min-height: 151px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    z-index: $modalZIndex;

    .unsaved-changes-header {
      font-size: 15.72px;
      color: #191414;
      font-weight: 600;
      margin-bottom: 12px;
      padding: 16px;
      border-bottom: 1px solid $gray400;

    }

    .unsaved-changes-message {
        padding: 16px;
    }

    .unsaved-changes-buttons {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      padding: 16px;
      border-top: 1px solid $gray400;
    }
}
</style>
